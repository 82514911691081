import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import PayOfficePage from "../components/payment-office-page"
import LayoutPay from "../components/layout-pay"

export const PaymentOffice = ({ data }) => (
  <>
    <SEO title="Home" />
    <PayOfficePage type="office" />
  </>
)

PaymentOffice.propTypes = {
  title: PropTypes.string,
}

const PaymentPage = ({ data }) => (
  <LayoutPay page="fast-page">
    <PaymentOffice data={data} />
  </LayoutPay>
)

export default PaymentPage

export const pageQuery = graphql`
  query PaymentPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "fast-page" } }) {
      frontmatter {
        mainFastSlide {
          title
          subtitle
        }
        toc {
          text
          riscTitle
          riscDescription
          tocTitle
          tocList {
            name
            hash
          }
        }
        howToGet {
          title
          image {
            publicURL
          }
          ways {
            way
            description
          }
        }
        contacts {
          title
          person {
            name
            position
            tel {
              code
            }
            email {
              code
            }
          }
        }
      }
    }
  }
`
