import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from "gatsby";
import Menu from '../menu/menu';
import Logo from '../../../static/images/svg/logo-new.svg';
import LogoBlack from '../../../static/images/svg/logo-black-new.svg';

import './style.sass';
import scrollTo from 'gatsby-plugin-smoothscroll';

class HeaderGreen extends Component {
    state = {
        openMenu: false
    }

    handleClick() {
        this.setState((state) => {
            return { openMenu: !state.openMenu }
        })
    }

    scrollHandler = () => {
        if (typeof window !== `undefined`) {
            const top = window.pageYOffset;

            this.scrollMenuHandler(top);
        }
    }

    scrollMenuHandler = (top) => {
        const header = document.querySelectorAll('.header')[0];
        const firstBlock = document.querySelectorAll('.wrapper--check')[0];
        const headerClass = 'header--fix';

        const startSwimMenuH = firstBlock.offsetHeight;

        if (top > startSwimMenuH) {
            if (!header.classList.contains(headerClass)) {
                header.classList.add(headerClass);
            }
        } else {
            if (header.classList.contains(headerClass)) {
                header.classList.remove(headerClass);
            }
        }
    }

    componentDidMount() {
        this.scrollHandler();
        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', this.scrollHandler);
            if(window.location.hash) {
                setTimeout(() => {
                    scrollTo(window.location.hash);
                }, 3000)
            }
        }

    }


    render() {
        const {openMenu} = this.state;

        return (
            <header className="header header--green">
                <div className="full-wrapper">
                    <div className="header__wrapper">
                        <div className="header__logo">
                            <Link
                                to="/"
                            >
                                <img src={Logo} alt="Технопарк «Сколково»"/>
                            </Link>
                        </div>
                        <div className={"header__menu " + (openMenu ? "active" : "")}>
                            <div className="header__menu-logo">
                                <img src={LogoBlack} alt="Технопарк «Сколково»"/>
                            </div>
                            <div className="header__burger-inner">
                                <div className={"header-burger active"} onClick={this.handleClick.bind(this)}>
                                    <span className="header-burger__global header-burger__global--top"></span>
                                    <span className="header-burger__global header-burger__global--middle"></span>
                                    <span className="header-burger__global header-burger__global--bottom"></span>
                                </div>
                            </div>
                            <Menu page={this.props.page} closeMenu={this.handleClick.bind(this)}/>
                        </div>
                        <div className="header__burger">
                            <div className={"header-burger"} onClick={this.handleClick.bind(this)}>
                                <span className="header-burger__global header-burger__global--top"></span>
                                <span className="header-burger__global header-burger__global--middle"></span>
                                <span className="header-burger__global header-burger__global--bottom"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

HeaderGreen.propTypes = {};

export default HeaderGreen;
