import React from "react";
import PropTypes from "prop-types";
import Footer from './footer/footer';
import {Helmet} from 'react-helmet';
import HeaderGreen from './header/header-green';

const LayoutPay = ({children, page}) => {
    return (
        <div className="page-wrapper">
            <Helmet>
                <script src="https://widget.cloudpayments.ru/bundles/cloudpayments" />
            </Helmet>
            <div className="page-wrapper__inner">
                <div className="page-wrapper__inner-content">
                    <HeaderGreen page={page} />
                    {children}
                </div>
                <Footer page={page} />
            </div>
        </div>
    )
}

LayoutPay.propTypes = {
    children: PropTypes.node.isRequired
}

export default LayoutPay;
