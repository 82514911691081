import React, { Component } from "react"
import "./style.sass"
import FormOffice from "../formOffice"
import FormCoworking from "../formCoworking"

class PayOfficePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
    }
  }

  componentDidMount() {}

  render() {
    const { width, data } = this.state

    return (
      <div className="wrapper wrapper--pay wrapper--check">
        <div className="full-wrapper">
          <div className="wrapper__content">
            <div className="payment">
              {this.props.type === "office" ? (
                <FormOffice />
              ) : (
                <FormCoworking location={this.props.location} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PayOfficePage.propTypes = {}

export default PayOfficePage
